<template>
  <div>
    <MainFrame offset="-7.5vh">
      <List>
        <ListItem to="/depts">
          <!-- @click="$dialog.alert({
                        title: '温馨提醒',
                        message: '云医院暂停使用中',
                    }).then(() => {});" -->
          <template #left>
            <img src="../assets/doctor.png" />
          </template>
          <template #right>
            <h4>云门诊</h4>
            <p>网上复诊就医，医生在线开药</p>
          </template>
        </ListItem>

        <ListItem to="/visit">
          <template #left>
            <img src="../assets/history.png" />
          </template>
          <template #right>
            <h4>就诊记录</h4>
            <p>查询既往记录，药品再来一单</p>
          </template>
        </ListItem>

        <ListItem to="/logistics">
          <template #left>
            <img src="../assets/car.png" />
          </template>
          <template #right>
            <h4>药品物流</h4>
            <p>实时查看进度，药品快递到家</p>
          </template>
        </ListItem>

        <ListItem to="/report">
          <template #left>
            <img src="../assets/report.png" />
          </template>
          <template #right>
            <h4>检查报告</h4>
            <p>检查化验报告，掌上查看信息</p>
          </template>
        </ListItem>

<!--        <ListItem @click="gotoPage">-->
<!--          <template #left>-->
<!--            <img src="../assets/report.png" />-->
<!--          </template>-->
<!--          <template #right>-->
<!--            <h4>检查报告</h4>-->
<!--            <p>检查化验报告，掌上查看信息</p>-->
<!--          </template>-->
<!--        </ListItem>-->
      </List>
    </MainFrame>
  </div>
</template>

<script>
  // @ is an alias to /src
  import MainFrame from '@/components/home-page/MainFrame.vue';
  import List from '@/components/home-page/List.vue';
  import ListItem from '@/components/home-page/ListItem.vue';
  export default {
    name: 'Home',
    components: {
        MainFrame,
        List,
        ListItem
    },
    beforeCreate(){
        this.$store.dispatch('pageData/setActiveTab', 'home');
    },
    mounted() {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://appx/web-view.min.js';
      document.body.appendChild(script);
    },
    methods: {
      gotoPage(){
        my.navigateTo({url: '/pages/Home/Home'});
      },
    }
  }
</script>

<style scoped="" lang="less">
  @import "../style/var.less";
  .left img{
    margin-left: 10px;
    width: 40px;
  }
  .right{
    h4{
      margin: 15px 0 5px;
    }
    p{
      font-size: @font-size-md;
      margin: 5px 0 15px;
    }
  }
</style>
